//import Module
import { Routes, Route } from 'react-router-dom';

//import Componenets
import Header from './components/header';
import Home from './components/home';
import Footer from './components/footer';
import Lyrics from './components/lyrics';
import Discography from './components/discography';

//import Style
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route paht="/discography" element={<Discography />} />
        <Route path="/lyrics/:albumUrl" element={<Lyrics />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
