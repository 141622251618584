import React from "react";

import albumArray from "../data/album.json"
import { useParams } from "react-router-dom";

import '../styles/lyrics.css'

function Lyrics(){

    const { albumUrl } = useParams();
    const albumContent = albumArray.albums.filter(content => content.url === albumUrl)

    return(
        <div className="lyricsHome">
            {albumContent.map(content => (
                <div className="lyricsContent">
                    <div className="lyricsImage">
                        <img src={content.img} alt={content.title}/>
                    </div>
                    <div className="lyrics">
                        {content.lyrics}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Lyrics