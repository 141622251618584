//import Module
import React from "react";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

import albumArray from "../data/album.json"

//import Style
import '../styles/home.css';

function NewBanner(){

    
    function bannerClose(){
        document.getElementsByClassName("homebanner")[0].style.display = "none";
    }

    return(
        <div className="homebanner">
            <img src="https://sekainoowari.jp/wp-content/uploads/2023/11/Nautilus_forSNS-766x423.jpg"/>
            <div className="hide" onClick={bannerClose}>
                X
            </div>
        </div>
    )
}

function NewestArticle(props){
    return(
        <div className="NewestAlbum" id="newAlbums">
            <div>
                <img src={props.imgSrc} oncontextmenu="return false;"/>
            </div>
            <div>
                <span>{props.albumTitle}</span>
            </div>
        </div>
    )
    
}

function Newest(){

    const { t } = useTranslation();

    const albumList = albumArray.albums.map((albums, index) => {
        return(
            <Link to={`/lyrics/${albums.url}`}>
                <NewestArticle 
                imgSrc={albums.img}
                albumTitle={albums.title}
                albumArtists={albums.artist}
                />
            </Link>
        )
    })

    return(
        <div className="NewestSection" id="NewestSection">
            <div className="hometitle">
                {t("newTitle")}
            </div>
            <div className="AlbumFive">
                {albumList.slice(0, 5)}
            </div>
            
        </div>
    )
}

export {Newest, NewBanner}

