//import Module
import React from "react";
import { useTranslation } from "react-i18next";

import {Newest, NewBanner} from "./newest";
import Topic from "./topic";

//import Style
import '../styles/home.css'

function Home(){

    const { t } = useTranslation()

    return(
        <div className="home">
            <Newest />
            <Topic />
        </div>
    )
}

export default Home