import React from "react";

function Discography(){
    return(
        <div>
            
        </div>
    )
}

export default Discography;