//import Module
import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";

//import Style
import '../styles/headfoot.css'

function Footer(){

    const { i18n } = useTranslation()
    const changeLangToEn = () => i18n.changeLanguage('en-US')
    const changeLangToKo = () => i18n.changeLanguage('ko-KR')
    const changeLangToJa = () => i18n.changeLanguage('ja-JP')

    function showHideLang(){
        const earth = document.getElementsByClassName('earth');
        const country = document.getElementsByClassName('country');

        earth[0].addEventListener("click", function(){
            country[0].classList.toggle('show');
        })
    }

    return(
        <div className="footer">
            <div className="copyright">
                (c) 2023 Lake.<br/>The information and images on this site are copyrighted to SEKAI NO OWARI.
            </div>
            {/* <div className="changeLanguage">
                <div className="earth" onClick={showHideLang}>
                    🌏
                </div>
                <div className="country">
                    <ul>
                        <li>
                            <button onClick={changeLangToEn}>English</button>
                        </li>
                        <li>
                            <button onClick={changeLangToJa}>日本語</button>
                        </li>
                        <li>
                            <button onClick={changeLangToKo}>한국어</button>
                        </li>
                    </ul>
                </div>
            </div> */}
        </div>
    )
}

export default Footer