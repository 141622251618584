//import Module
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//import Style
import '../styles/home.css'

function Topic(){

    const [info, setInfo] = useState([]);
   
    useEffect(() => {
        axios.get('https://sekainoowari.jp/wp-json/wp/v2/info')
        .then(response => {
            setInfo(response.data)
        })
        .catch((error) => console.log(error))
    }, [])

    return(
        <div className="topicHome">
            <div className="topictitle">
                INFO
            </div>
            <div style={{width: "fit-contents"}}>
                {info.map(info => {
                    return(
                    <a href={info.link} target="blank" >
                        <div className="infos" key={info.id}>
                            <span style={{fontWeight:600, fontSize:"17px"}}>
                                {info.title.rendered}
                            </span>
                            <span style={{fontWeight:400, color:"gray", fontSize:"12px"}}>
                                {info.date.slice(0,10)}
                            </span>
                        </div>
                    </a>
                    )
                })}
            </div>
        </div>
    )
}

export default Topic